<template>
  <div>
    <div ref="eMenu" class="toolbar"></div>
    <div ref="editor" style="text-align:left;" class="text"></div>
    <i ref="icon" class="iconfont icon-open" :class="['bottom-icon', isA ? 'active' : '']" />
  </div>
</template>

<script>
import E from 'wangeditor'
import Cookies from 'js-cookie'
import ossApi from '../../api/oss.js'

export default {
  name: 'Editor',
  props: {
    onChange: {
      type: Function
    }
  },
  data() {
    return {
      editor: null,
      uploadUrl: '',
      isA: false
    }
  },
  mounted() {
    let xss = require('xss')
    this.editor = new E(this.$refs.eMenu, this.$refs.editor)
    this.editor.customConfig.zIndex = 100 // 设置 z-index 小于 999, 也就是 NavMenu 的 z-index
    this.editor.customConfig.menus = [
      // 'head', // 标题
      'bold', // 粗体
      'fontSize', // 字号
      'italic', // 斜体
      'underline', // 下划线
      // 'strikeThrough', // 删除线
      'foreColor', // 文字颜色
      'backColor', // 背景颜色
      // 'link', // 插入链接
      // 'list', // 列表
      'justify', // 对齐方式
      // 'quote', // 引用
      'image', // 插入图片
      'code' // 插入代码
      /*'undo', // 撤销
      'redo' // 重复*/ //菜单栏太长，删两个。
    ]
    this.editor.customConfig.uploadImgServer = this.uploadUrl + '/upload'
    let token = Cookies.get('token')
    this.editor.customConfig.uploadImgMaxLength = 1
    this.editor.customConfig.uploadImgHeaders = {
      Authorization: 'bearer ' + token
    }
    this.editor.customConfig.customUploadImg = (files, insert) => {
      ossApi.getQuestionFileToken(files[0].name).then(res => {
        const data = res.res
        const formData = new FormData()
        formData.append('key', data.dir)
        formData.append('OSSAccessKeyId', data.accessId)
        formData.append('policy', data.policy)
        formData.append('Signature', data.signature)
        formData.append('file', files[0])
        const url = `https://${data.host}`
        fetch(url, {
          method: 'POST',
          body: formData
        }).then(() => {
          const fullPath = `https://${data.host}/${data.dir}`
          insert(fullPath)
        })
        this.uploadUrl = `https://${data.host}`
        this.uploadParams.key = data.dir
        this.uploadParams.OSSAccessKeyId = data.accessId
        this.uploadParams.policy = data.policy
        this.uploadParams.Signature = data.signature
      })
    }
    this.editor.customConfig.uploadFileName = 'file'
    this.editor.customConfig.onchange = html => {
      this.onChange(xss(html))
    }
    this.editor.create()
    this.mouseMove(this.$refs.icon)
  },
  methods: {
    setInitContent(html) {
      this.editor.cmd.do('insertHTML', html)
    },
    clearEditor() {
      this.editor.txt.clear()
    },
    modifyEditor(data) {
      this.editor.txt.html(data)
    },
    mouseMove(event) {
      let that = this
      event.onmousedown = function(ev) {
        that.isA = true
        // 鼠标按下时的位置
        let mouseDownY = ev.clientY
        let H = that.$refs.editor.offsetHeight
        document.onmousemove = function(ev) {
          var ev = ev || event
          // 鼠标移动时的鼠标位置
          var mouseMoveY = ev.clientY
          //根据改变方块大小的方向不同进行大小的改变
          // 下
          that.$refs.editor.setAttribute('style', 'height:' + (mouseMoveY - mouseDownY + H) + 'px!important')
          if (parseInt(that.$refs.editor.style.height) < 50) {
            that.$refs.editor.style.height = 50 + 'px'
          }
        }
        document.onmouseup = function() {
          document.onmousemove = null
          that.isA = false
          // 释放全局捕获
          if (event.releaseCapture) {
            event.releaseCapture()
          }
        }
      }
    }
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
}
.toolbar {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
}
.text {
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1;
}
.active {
  cursor: s-resize;
}
.bottom-icon {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 16px;
  bottom: 0;
  text-align: center;
  line-height: 20px;
  z-index: 10001;
}
</style>
